import "index.css"
import "privacy.css"
import "syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")

document.getElementById('current_year').textContent = new Date().getFullYear()
